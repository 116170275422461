.container {
  width: 100%;
  padding: 0 80px;
  position: relative;
  padding-top: 40px;
  min-height: 232px;
  & > img {
    width: 100%;
  }
  .bannerText {
    position: absolute;
    top: 31px;
    left: 136px;
    height: calc(100% - 11px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .bannerTitle {
      color: #60646C;
      font-size: 20px;
      line-height: 1;
      margin-bottom: 4px;
    }
    .bannerTarget {
      font-size: 26px;
      color: #1C2024;
      line-height: 40px;
      font-weight: 600;
      margin-bottom: 16px;
      .color {
        font-size: 32px;
        color: #5B3DE9;
        font-family: Geist;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
      }
    }
    .button {
      width: 160px;
      height: 32px;
      line-height: 32px;
      border-radius: 8px;
      background-color: #09090B;
      color: #fff;
      text-align: center;
      margin-bottom: 12px;
      cursor: pointer;
    }
    .link {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      > a {
        text-decoration-line: underline;
        color: #0C67E6;
        cursor: pointer;
      }
    }
  }
}
