.content {
  max-height: 60vh;
  overflow: auto;
}
.mobileContent {
  max-height: 70vh;
  min-height: 45vh;
  overflow: auto;
}
.desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #60646C;
  margin-bottom: 16px;
}
