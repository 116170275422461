@tailwind base;
@tailwind components;
@tailwind utilities;


/* 字体 */
/* 100 - Thin(Hairline)
200 - Extra Light (Ultra Light)
300 - Light
400 - Regular (Normal、Book、Roman)
500 - Medium
600 - Semi Bold (Demi Bold)
700 - Bold
800 - Extra Bold (Ultra Bold)
900 - Black (Heavy) */

@font-face {
    font-family: "Inter";
    src: url("../public/Inter-VariableFont_slnt\,wght.ttf");
}

@font-face {
    font-family: "Inter-Medium";
    src: url("../public/Inter-Medium.ttf");
}

@font-face {
    font-family: "Geist";
    src: url("../public/GeistVF.ttf");
}

@font-face {
    font-family: "Geist";
    font-weight: 600;
    src: url("../public/fonts/Geist-SemiBold.woff2") format('woff2'), url("../public/fonts/Geist-SemiBold.woff") format('woff');
}

@font-face {
    font-family: "Geist-Medium";
    font-weight: 600;
    src: url("../public/GeistVF.ttf");
}

#app{
    background-color: white;
    margin: 0 auto;
    height: 100vh;
    /* overflow: scroll; */
}
.webAppContainer {
  min-width: 1280px;
}
.app-mobile{
    width: 390px;
}

.app-web-on-pc{
    width: 100vw;  
}

#root{
    background-color: #565656;
    width: 100vw;
    height: 100vh;
    /* overflow: scroll; */
}

.app-web-iframe-box{
    width: 100%;  
    overflow-x: scroll;
}

.app-web-iframe{
    width: calc(390px + 100px);  
}

.app-mobile-iframe{
    width:100%;
}

.app-detail-bg{
    background-color: #f6f5fa;
    background-image: url('../public/detail-bg.png');
    background-repeat: no-repeat;
    background-size: 390px 500px;
    background-position-y: -64px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.animate-fade-in {
    animation: fadeIn 0.3s forwards; /* 动画持续时间与过渡时间一致 */
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
    from {
      transform: translateY(0);
      opacity: 0;
    }
    to {
      transform: translateY(-100%);
      opacity: 1;
    }
  }

.animate-slide-down {
  animation: slideDown 0.3s forwards; /* 动画持续时间 */
}

.animate-slide-up {
    animation: slideUp 0.3s forwards; /* 动画持续时间 */
  }