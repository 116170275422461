.radioBox{
	display: flex;
	padding: 16px;
	margin: -16px;
	cursor: pointer;
}
.svgIcon {
	width: 2em;
	height: 2em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
