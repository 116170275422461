.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding-top: 56px;
    .title {
      font-family: Geist;
      font-size: 26px;
      font-weight: 600;
      line-height: 40px;
      color: #1C2024;
      margin-bottom: 8px;
    }
    .desc {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      color: #71717A;
    }
    .content {
      margin-top: 40px;
      border: 1px solid #E4E4E7;
      border-radius: 16px;
      padding: 24px;
      width: 515px;
      box-sizing: border-box;
      .contentItem {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 24px;
        .itemTitle {
          display: flex;
          align-items: center;
          gap: 4px;
        }
        .itemInput {
          > input {
            width: 100%;
            height: 36px;
            border: 1px solid #E4E4E7;
            border-radius: 8px;
            padding-left: 12px;
            line-height: 36px;

            &:focus, &:active, &:focus-visible {
              outline: none;
            }
          }
        }
        .itemError  {
          color: #D43710;
          font-size: 12px;
        }
      }
    }
    .lock {
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      color: #1C2024;
      margin-top: 40px;
    }
  }

  .bg {
    width: 595px;
    height: calc(100vh - 81px);
    flex-shrink: 0;
    background: linear-gradient(#5b3de980, #fff);
    .img {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 840px;
      img {
        width: 470px;
      }
    }
  }
}

.mobileContainer {
  width: 100%;
  padding: 0 16px;
}

@media screen and (max-width: 1168px) {
  .container {
    .wrapper {
      .main {
        margin: 0 auto;
      }
    }
    .bg {
      display: none;
    }
  }
}

