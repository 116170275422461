.container {
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    width: 32px;
    height: 24px;
    border-radius: 6px;
    background-color: #eff1ff;
    color: #2B1F74;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
  }
  .text {
    font-family: 'Inter';
    font-size: 12px;
    margin-left: 4px;
    color: #8B8D98;
  }
}
