.container {
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 180px;
  
  .title {
    font-size: 26px;
    font-weight: 600;
    line-height: 40px;
    color: #1C2024;
    margin-bottom: 24px;
  }
  .address {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &.line {
      border-bottom: 1px solid #EEEEEE;
      padding-bottom: 24px;
      margin-bottom: 24px;
      height: 297px;
      box-sizing: border-box;
    }
    .addressItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .addressItemLeft {
        color: #60646C;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
      .addressItemRight {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #1C2024;
        display: flex;
        align-items: center;
      }
      .pending {
        display: flex;
        align-items: center;
        gap: 6px;
        background-color: #FFEDDC;
        height: 22px;
        border-radius: 11px;
        padding: 0 16px;
        color: #B06700;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        .clock {
          color: #B06700;
        }
      }
    }
  }
  .trackingInfo {
    position: absolute;
    width: 100%;
    padding: 0 24px;
    left: 0;
    top: 442px;
    display: none;
    .trackingInfoContent {
      padding: 16px;
      background-color: #fff;
      border-radius: 16px;
      width: 100%;
      border: 1px solid #E8E8EC;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        margin-bottom: 16px;
        .headerRight {
          display: flex;
          align-items: center;
        }
      }
      .transit {
        font-size: 12px;
        margin-bottom: 16px;

        .dot {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #5B3DE9;
          flex-shrink: 0;
        }
        .time {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          margin-bottom: 4px;
          .transitTitle {
            color: #1C2024;
            font-weight: 500;
          }
          .transitTime {
            color: #60646C;
          }
        }

        &:last-child {
          margin-bottom: 0;

          .dot {
            background-color: #D9D9E0;
          }
        }
        .desc {
          padding-left: 12px;
        }
      }
    }
  }
  &:hover {
    .trackingInfo {
      display: block;
    }
  }
}
