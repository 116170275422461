.container {
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 16px;
  padding: 32px 16px 16px 16px;
  width: calc((100% - 64px) / 3);
  flex-shrink: 0;
  align-self: stretch;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  &.last {
    margin-right: 0;
  }
  .logo {
    width: 80px;
    height: 80px;
    border-radius: 12px;
    border: 1px solid #eee;
    overflow: hidden;
    margin-bottom: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 100%;
    }
  }
  .content {
    padding-bottom: 16px;
    .title {
      font-family: 'Geist';
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #1C2024;
      display: flex;
      align-items: center;

      .notInterest {
        background: #8B8D98;
        height: 20px;
        line-height: 20px;
        padding: 0 4px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        margin-left: 4px;
      }
    }
    .desc {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #60646C;
    }
    .dealsStatus {
      margin-top: 4px;
      padding: 0 12px;
      background-color: #F5FBF5;
      width: fit-content;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #4CAF50;
      display: flex;
      align-items: center;
      height: 20px;
      border-radius: 10px;
      .cycle {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #4CAF50;
        margin-right: 12px;
      }
      &.dealsStatusBlue {
        background-color: #F2FAFF;
        color: #1E78FF;
        .cycle {
          background-color: #1E78FF;
        }
      }
      &.scriptStatus {
        background-color: #FFF6EE;
        color: #FF9315;
        .cycle {
          background-color: #FF9315;
        }
      }
      &.cancelStatus {
        background-color: #F0F0F3;
        color: #60646C;
        .cycle {
          background-color: #60646C;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1C2024;
    border-top: 1px solid #eee;
    padding-top: 12px;
    .money {
      font-family: 'Geist';
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    .button {
      width: 78px;
      height: 32px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #5A31F0;
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      &.notInterestButton {
        background-color: #fff;
        border: 1px solid #E4E4E7;
        color: #1C2024;
      }
    }
    .submit {
      width: 102px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      color: #fff;
      background-color: #5A31F0;
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      span {
        margin-right: 4px;
      }
      &.blueSubmit {
        background-color: #fff;
        border: 1px solid #E4E4E7;
        color: #1C2024;
        .rightSubmit {
          color: #1C2024;
        }
      }
    }
  }
  &.mobileContainer {
    width: 265px;
    // margin-right: 16px;
    .content {
      .title {
        font-size: 16px;
      }
    }
    &.oneContainer {
      flex: 1;
    }
  }
}
