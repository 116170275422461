.confirmDesc {
  margin-top: 16px;
  margin-bottom: 8px;
  .li {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #60646C;
    display: flex;
    align-items: flex-start;
    padding: 8px 0;
    margin-bottom: 8px;

    .joinDrawIcon {
      flex-shrink: 0;
      margin-right: 8px;
    }
    .timeText {
      color: #5B3DE9;
    }
  }
}
.agree {
  display: flex;
  align-items: center;
  font-family: Inter;
  height: 24px;
  font-weight: 500;
  font-size: 14px;
  .linkText {
    padding-left: 4px;
    color: #0C67E6;
    cursor: pointer;
    text-decoration: underline;
  }
}
.cancelButton {
  width: 33%;
  flex-shrink: 0;
  height: 38px;
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  color: #1C2024 !important;
  background-color: #FFFFFF !important;
  border: 1px solid #E8E8EC !important;
  border-radius: 8px !important;
  text-wrap: nowrap!important;
  padding: 0!important;
}

.submitButton {
  // margin: 24px 0 8px !important;
  width: 100%;
  flex: 1;
  height: 38px;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  color: white !important;
  background-color: #5A31F0 !important;
  border-radius: 8px !important;
}

.webContainer {
  .li {
    align-items: center;
    padding: 0;
    margin-bottom: 16px;
    .joinDrawIcon {
      width: 26px;
      height: 26px;
      
    }
  }
}
