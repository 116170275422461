.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 81px);
  .linked {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Geist;
    width: 390px;
  }

  .linkedImg {
    margin: 32px 0 16px;
    width: 254px;
    height: 191px;
  }

  .desc {
    width: 330px;
    height: 44px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #60646c;
  }
  .title {
    font-family: Geist;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    text-align: center;
    color: #1c2024;
  }
  .detail {
    margin-top: 16px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

    color: #60646c;

    background-color: #f9f9fb;
    padding: 8px 24px;
    border-radius: 8px;
    p {
      margin: 4px 0;
    }
  }
}

