.container {
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  .logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    box-sizing: border-box;
  }

  .content {
    flex: 1;
    .title {
      font-size: 16px;
      font-weight: 600;
      color: #1c2024;
      line-height: 24px;
      font-family: 'Geist';
      margin-bottom: 4px;
    }
    .desc {
      font-size: 16px;
      color: rgba(28, 50, 72, 0.65);
      line-height: 24px;
      font-family: 'Inter';
    }
  }
  .close {
    width: 24px;
    height: 24px;
    // position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 30px;
    top: 50%;
    margin-top: -12px;
    cursor: pointer;
  }

  &.mobileContainer {
    align-items: flex-start;
    padding: 16px;
    .close {
      width: 20px;
      height: 20px;
      right: 16px;
      top: 16px;
      margin-top: 0;
    }
    .content {
      .title {
        font-size: 14px;
        line-height: 20px;
      }
      .desc {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

