.container {
  background:#FDFDFF;
  border: 1px solid #eee;
  border-radius: 16px;
  padding: 32px 24px 44px 24px;
  min-width: 196px;
  width: calc((100vw - 160px - 128px) / 5);

  .title {
    font-family: Geist;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-top: 24px;
    color: #1C2024;
  }
  .desc {
    margin-top: 8px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}
.influencersList {
  margin-top: 24px;
  .influencersListItem {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    > img {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
    > span {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      margin-left: 4px;
      margin-right: 4px;
      color: #60646C;
    }
  }
}
.mobileContainer {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  .icon {
    position: relative;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }
  .content {
    margin-left: 12px;
    .title {
      font-family: Geist;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }
    .desc {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .influencersList {
    margin-top: 4px;
    .influencersListItem {
      padding: 6px 8px;
      border: 1px solid #eee;
      width: fit-content;
      border-radius: 8px;
      margin-bottom: 6px;
    }
  }
}
