div.markdownContainer > ul > li {
  margin: 10px 0;
  list-style: none;
  border-image-slice: 1;
  padding-left: 13px;
  position: relative;
  &:before {
    display: block;
    content: '';
    margin-right: 10px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGySURBVHgBpZW7TkJBEIZnF2IUGvBwsTDhnMbEChLtfQS0oSNCZautFmChrVJagYmVjfAG2lvIEwCJhVxOoAELAuvMCRAuswTha/Yy7M/sP8wigKFSUT6vF1JSwokQEFMKTNrHeRXnX8MhlEIhUeDOivmNel2dulyQx6kPllNF4dt5YTm9sG31gGJvK4gRJt4gj2cybIYUwOtkYQ3QikfDEFcTwUZDkV952IyzQEAUHcFWS1VwMGEzOmiX5absdGJ2cwDPTzZ81/rQ6w0herQDiaQfjKCL+7iv34eUaDZVET2Ic2J31z+O0DQej4Sb+z1WFGtQkuhdhPu615f2ghhBe5Q1ByYWlaga44Llz1/QQRZoMKUu4vEIXYjNfIykduIC+5Et7SEqDge1pcT2KXPB84tdNkvaSyS1jVQjD9+5iBF0O9WMHe9MhA4Ot0cVdoMmw6Jot5VvMAD6Ya/Sv8uoYqdY0u8XHVykYUPo5aHRqTL1IA45WBO86uQZm3EduyaLVc/A/8hhQpfjxUIZRy8PiZqwnA56nw6HnduBVnBOOI4Zm+NumvoL+Oh2oWBZjv8z/AEkiaQGTtvBKQAAAABJRU5ErkJggg==')
      no-repeat left center;
    height: 16px;
    width: 12px;
    left: -6px;
    margin-top: 3px;
    background-size: contain;
    vertical-align: baseline;
    position: absolute;
  }
}

.markdownContainer ul > li {
  margin: 10px 0;
  list-style: none;
  padding-left: 13px;

  position: relative;

  &:before {
    position: absolute;
    display: block;
    content: '';
    margin-right: 10px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEVSURBVHgBpZM9UsMwEIWf1p54kqHgCL4BoaRgJiooXIFvwg2wb0BOQlK5SOHMUNCKE+AjUEBCSORllQQwFBlb+Rr9jN7uSvuk8I/Z7HFkObhRhGsw4v22UUoZG3zkidZV87z6njyU5elgE93J9BYHIOD+LVzlqdavPwGcuL/uzSXLGdphFuFKuyDkVi5zB7FjeLKrFqooy5g20Qs8IFhNIs7giXtsuQJ3Kf0PrlMSQA3hi7SZcCQkjazgiXjAEIOn8EXcScz1BJ7YNXJKri7nrHiMjjBjnCQX1fYRo+Azk8G0Ftf8vHzvOw22AbR4uifeblOJy7xcDEZpev77mZoUxVNMIWc7g+09Ip3iGlNWduKu3Dz/BaNCYwixrUtNAAAAAElFTkSuQmCC')
      no-repeat left center;
    height: 12px;
    width: 4px;
    left: -2px;
    margin-top: 6px;
    background-size: contain;
    vertical-align: baseline;
  }
}

.markdownContainer ul > li:not(:last-child) {
  border-left: 1px solid transparent;
  border-image: linear-gradient(
    to bottom,
    transparent 0 30px,
    #e8e8ec 30px 100%
  );
  border-image-slice: 1;
}

.markdownContainer ul {
  padding-inline-start: 10px;
}

.markdownContainer {
  display: block;
  position: relative;
  margin: 20px;
  margin-top: 0;
  font-family: Inter;
  color: #60646C;
  font-weight: 400;
  border-top: 1px solid #E8E8EC;
  padding-top: 8px;
}

.markdownContainer strong {
  color: #1C2024;
}

:root {
  --container-width: 800px;
  --body-bg: #fff;
  --body-color: #fff;
  --collapse-bg: #fff;
  --collapse-color: #1a1a1a;
  --collapse-line-color: #e8e8ec;
  --collapse-icon-color: #1c2024;
}

.collapsible details {
  background: var(--collapse-bg);
  color: var(--collapse-color);
  border: 1px solid #e8e8ec;
  border-radius: 8px;
  margin-bottom: 24px;
}

.collapsible summary {
  display: grid !important;
  grid-template-columns: 1fr auto;
  align-items: center;
  cursor: pointer;
  list-style-type: none;
  padding: 16px;
  border-radius: 8px;
  font-family: 'Geist';
  font-weight: 600;
  font-size: 14px;
}

.collapsible summary::-webkit-details-marker {
  display:none;
}

.detailsInfo {
  border-top: 1px solid transparent;
  border-image: linear-gradient(
    to right,
    transparent 0 2%,
    #e8e8ec 2% 98%,
    transparent 98% 100%
  );
  border-image-slice: 1;
  position: relative;
}

.collapsible summary svg {
  display: inline-block;
  width: 24px;
  height: 24px;
  transition: transform 0.26s;
  color: var(--collapse-icon-color);
}

.collapsible details[open] summary svg {
  transform: rotate(90deg);
}

.collapsible details[open] {
  summary {
    border: none;
  }
}

@keyframes growAndFadeIn {
  0% {
    font-size: 0;
    opacity: 0;
  }
  90% {
    font-size: 1em;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.items {
  .itemsTitle {
    color: #1C2024;
    font-family: Geist;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    height: 40px;
  }
  .itemsUl {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #1C2024;
  }
  .img {
    margin-bottom: 24px;
    width: 60%;

    > img {
      width: 100%;
    }
  }
}

.collapsible {
  padding: 24px 32px;

  &.collapsibleMobile {
    padding: 0;

    .img {
      margin-bottom: 24px;
      width: 100%;
  
      > img {
        width: 100%;
      }
    }
  }
}
