.video-preview {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #D9D9E0;
  position: relative;

  video {
    width: 56px;
    height: 56px;
    object-fit: cover;
  }

  > .preview-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  > .close {
    position: absolute;
    top: -4px;
    right: -6px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .close-icon {
      color: #fff;
      font-size: 16px;
    }
  }
}

.video-operations {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  .preview-icon {
    color: white;
    font-size: 24px;
    cursor: pointer;
    margin-right: 8px;
  }

  .close {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}
