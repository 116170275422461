.feedback-media-list {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
  .images-list {
    display: flex;
    align-items: center;
    gap: 16px;
    &-item {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid #D9D9E0;
      position: relative;

      > .preview-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }

      > .close {
        position: absolute;
        top: -4px;
        right: -6px;
        cursor: pointer;
        background-color: #fff;
        border-radius: 50%;
        display: inline-block;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .close-icon {
          color: #fff;
          font-size: 16px;
        }
      }

      .ant-image {
        cursor: pointer;
        width: 56px;
        height: 56px;
        .ant-image-mask {
          display: none;
        }
        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
