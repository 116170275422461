.modalContainer {
  padding: 24px;

	.modalHead {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 4px;

		.title {
			font-family: 'Geist';
			font-size: 20px;
			font-weight: 600;
			line-height: 32px;
		}

		.close {
			width: 20px;
			height: 20px;
			cursor: pointer;
		}
	}
	.footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		margin-top: 16px;

		.cancel {
			width: 95px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			border: 1px solid #5A31F0;
			margin-right: 8px;
			color: #5A31F0;
			font-weight: 500;
			font-size: 14px;
			cursor: pointer;
		}

		.submit {
			min-width: 95px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			background-color:#5A31F0;
			color: #fff;
			font-weight: 500;
			font-size: 14px;
			cursor: pointer;
      &.submitDisabled {
        background-color:#9898ff;
        cursor: no-drop !important;
      }
		}
	}
	.loading {
		width: 14px !important;
		height: 14px !important;
		margin-right: 8px;
		color: #fafafa;
	}
}
