.container {
  background: linear-gradient(90deg, #E4E7FF 0%, #EFF1FF 55.5%, #F7F8FF 100%);
  padding: 8px 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #E4E7FF;
  .content {
    .title {
      font-family: Inter;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 4px;
    }
    .desc {
      display: flex;
      align-items: center;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #1C2024;
      .purple {
        color: #5B3DE9;
        margin: 0 2px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #5A31F0;
    cursor: pointer;
    .close {
      width: 16px;
      height: 16px;
      color: #5A31F0;
      margin-left: 16px;
    }
  }
}

.mobile {
  padding-right: 16px;
  margin-bottom: 16px;
}
