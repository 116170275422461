.segmentedControl {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 36px;
    border-radius: 8px;
    background: #F9F9FB;
  }
  
  .option {
    margin: 1px;
    border: 1px solid transparent;
    cursor: pointer;
    text-align: center;
    flex: 1;
    transition: background-color 0.3s;
    line-height: 33px;
    font-size: 14px;
    font-family: 'Geist';
  }
  
  .selected {
    background-color: white;
    border: 1px solid #D9D9E0;
    border-radius: 6px;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04);
    font-weight: 600;
  }
  
  .option:not(:last-child) {
    // border-right: 1px solid #ccc;
  }
