.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: calc(100vh - 81px);
    margin-top: 32px;
    .main {
      height: 840px;
      width: 100%;
      max-width: 530px;
      .logo {
        height: 40px;
        position: absolute;
        top: 20px;
        left: 80px;
      }
      .title {
        font-family: Geist;
        font-size: 26px;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        color: #1c2024;
      }
      .desc {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #60646c;

        .doc {
          cursor: pointer;
          color: #0c67e6;
        }
      }
      .connect {
        margin: 40px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 88px;
        border-radius: 16px;
        background-color: #f9f9fb;
        padding: 0 24px;
        .platform {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Geist;
          font-size: 20px;
          font-weight: 600;
          line-height: 32px;
          text-align: left;
          color: #1c2024;

          .icon {
            width: 37px;
            margin-right: 22px;
          }
        }
        .connectBtn {
          cursor: pointer;
          // width: 151px;
          padding: 0 16px;
          height: 40px;
          background-color: #18181b;
          border-radius: 6px;

          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 16.94px;
          color: #fafafa;
        }
      }
      .tips {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #60646c;
        border-top: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        padding: 8px 0;
        margin-bottom: 40px;
        p {
          margin: 8px 0;
        }
      }
      .bottom {
        .secure {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #1c2024;
          .lockIcon {
            height: 12px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .bg {
    width: 595px;
    height: calc(100vh - 81px);
    flex-shrink: 0;

    background: linear-gradient(#5b3de980, #fff);
    .img {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 840px;
      img {
        width: 470px;
      }
    }
  }
}

.mobileContainer {
  padding: 0 16px;
  .wrapper {
    justify-content: flex-start;
    .main {
      width: 100%;
      .logo {
        display: none;
      }
      .title {
        font-size: 24px;
      }

      .connect {
        margin: 32px 0;

        .connectBtn {
          display: none;
        }
      }
      .tips {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #60646c;
        border-top: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        padding: 8px 0;
        p {
          margin: 8px 0;
        }
      }

      .bottom {
        position: fixed;
        bottom: 32px;
        width: calc(100vw - 32px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .secure {
          margin-top: 16px;
        }
        .connectBtn {
          cursor: pointer;
          width: 100%;
          padding: 0 16px;
          height: 40px;
          background-color: #18181b;
          border-radius: 6px;

          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 16.94px;
          color: #fafafa;
        }
      }
    }
  }
  .bg {
    display: none;
  }
}

@media screen and (max-width: 1168px) {
  .container {
    .wrapper {
      .main {
        margin: 0 auto;
      }
    }
    .bg {
      display: none;
    }
  }
}

