.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .item {
    display: flex;
    align-items: flex-start;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 44px;
      justify-content: center;
      .circle {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #F0F0F3;
        flex-shrink: 0;
        color: #60646C;
        font-size: 12px;

        .icon {
          color: #b9bbc6;
        }
      }
      .text {
        font-size: 12px;
        line-height: 20px;
        color: #60646C;
        margin-top: 4px;
      }
    }
    .line,.lineLong {
      flex: 1;
      height: 24px;
      display: flex;
      align-items: center;
      .lineItem {
        height: 4px;
        border-radius: 2px;
        background-color: #F0F0F3;
        width: 40px;
      }
    }
    .lineLong{
      .lineItem{
        width: 88px;
      }
    }
  }
  .activeItem {
    .content {
      .circle {
        background-color: #5B3DE9;
        color: #fff;
        .icon {
          color: #fff;
        }
      }
      .text {
        color: #5B3DE9;
      }
    }
    .line {
      .lineItem {
        background-color: #5B3DE9;
      }
    }
  }
  .focusItem {
    .content {
      .circle {
        background: #EFF1FF;
        color: #5B3DE9;
        .icon {
          color: #5B3DE9;
        }
      }
      .text {
        color: #5B3DE9;
        font-weight: 500;
      }
    }
  }
  &.pcContainer {
  .item {
    .line {
      .lineItem {
        width: 60px;
      }
    }
    .lineLong {
      .lineItem {
        width: 112px;
      }
    }
  } 
  }
}

