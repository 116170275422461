#aha-swiper>.swiper-pagination {
    bottom: 20px;
    height: 30px;

    &>.swiper-pagination-bullet {
        width: 6px!important;
        height: 6px!important;
        background: #9898ff!important;
        opacity: 0.3;
    }

    &>.swiper-pagination-bullet-active {
        background: #9898ff!important;
        opacity: 0.3;
    }
}




#drawerCtn {
    &>.MuiDrawer-paperAnchorBottom {
        padding: 16px 15px 16px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        width: 390px;
        left: calc(50% - 195px);
    }

    & .MuiCheckbox-colorPrimary {
        padding: 5px;
        color: #1C2024;
    }

    & .Mui-checked {
        color: #5A31F0;
    }

    & .MuiSvgIcon-root {
        width: 18px;
        height: 18px;
    }

    & textarea {
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: none;
        background-color: transparent;
        font-size: inherit;
        width: 100%;
    }

    & .MuiButton-contained {
        box-shadow: none !important;
    }

    .PrivateRadioButtonIcon-root-5 .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
    }

    // 侧边抽屉 Drawer
    .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16{
        right: calc(50% - 195px)!important;
    }
}

.MuiButton-root {
    text-transform: none !important;
}

#payment {
    & .PrivateSwitchBase-root-1 {
        padding: 0px !important;
        color: #1C2024;
    }

    & .Mui-checked {
        color: #5A31F0;
    }
}

#detail {
    & .MuiTabs-scroller {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        color: #60646C;
        background: linear-gradient(to bottom,rgba(255, 255, 255, 0) 0 44%, #F9F9FB 44% 54%, #FFFFFF 54% 100%);
    }

    & .MuiTabs-flexContainer{
        display: flex;
        align-items: flex-end;
    }
    & .MuiTab-root {
        width: 33.33%;
        height: 48px;
        text-transform: none !important;
        border-bottom: 1px solid #F9F9FB;
    }

    & .Mui-selected {
        color: #1C2024;
    }

    & .MuiTabs-indicator {
        background-color: #5A31F0;
    }
}

#checkList {
    & .MuiButtonBase-root {
        width: initial;
    }

    .MuiAccordionDetails-root {
        padding: 0 16px;
    }

    .MuiAccordionSummary-root {
        margin: 0 16px;
        padding: 0;
        height: 56px;
    }

    .MuiTypography-root {
        font-size: 16px;
        font-family: Geist-Medium;
        line-height: 24px;

    }

    .MuiAccordionSummary-root {
        border-bottom: 1px solid #E8E8EC;
    }

    .MuiPaper-root {
        box-shadow: none;
    }

}

.MuiDrawer-paperAnchorBottom {
    width: 390px;
    left: calc(50% - 195px)!important;
}

#submitDraftCtn {

    .MuiDrawer-paperAnchorBottom {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        overflow: hidden;
    }

    .MuiRadio-root {
        padding: 0;
    }

    .Mui-checked {
        color: #5A31F0;
    }

}

.MuiButton-contained {
    box-shadow: none !important;
}

.MuiDialog-paper {
    width: 312px;
    padding: 24px 16px;
    border-radius: 16px !important;
}

#standard-basic-label {
    display: none;
}

textarea:focus {
    outline: none;
}

#customRadio {
    .MuiFormGroup-root {
        flex-direction: row;
    }

    .PrivateSwitchBase-root-1 {
        padding: 0 8px 0 0;

    }

    .Mui-checked {
        color: #5A31F0;
    }
}

.MuiTabs-root {
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 99;
}

#about {
    overflow: visible;
}

#first {
    animation: slidein 2s infinite;
}

@keyframes slidein {
    from {
        scale: 0;
    }

    to {
        translate: 1;
    }
}

#agree {
    & .MuiRadio-root {
        padding: 2px 10px 2px 2px;
    }
}

#tabCtn {


    & .MuiTab-root {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        color: #60646C;
    }

    & .Mui-selected {
        color: #1C2024;
    }
}

#detailTab {
    width: 390px;
    border-bottom: 1px solid #E8E8EC;
}

#rDialog {
    .MuiDialog-paper {
        padding: 0 !important;
        width: 280px;
        overflow-y: visible;
    }
}
