.modalContainer {
  padding: 0;
}
.container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Geist';
    color: #1c2024;
  }
  .desc {
    margin-bottom: 32px;
    font-size: 16px;
    color: #60646c;
    line-height: 24px;
    text-align: center;

    .descSuccess {
      width: 88%;
      margin: 0 auto;

      .requirements {
        color: #0C67E6;
        cursor: pointer;
      }
    }
  }
  .button {
    width: 400px;
    height: 40px;
    border-radius: 8px;
    background-color: #5a31f0;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      background-color: #5a31f0;
    }
  }
}

.maybe {
  margin-top: 8px;
  color: #60646c;
  font-size: 14px;
  &:hover {
    background-color: #fff!important;
  }
}

.mobileContainer {
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .button {
    width: 294px;
  }
}
