.container {
  background-color: #f7f7f7;
  .title {
    display: flex;
    padding: 40px 80px;
    font-size: 26px;
    line-height: 40px;
    font-weight: 600;
    color: #1C2024;
  }
  .notices {
    padding: 0 80px;
    margin-bottom: 60px;
  }
  .invites {
    padding: 0 80px;
    margin-bottom: 60px;

    .invitesHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      margin-bottom: 8px;
      .headTitle {
        font-family: 'Geist';
        font-size: 20px;
        font-weight: 600;
        line-height: 40px;
        color: #1C2024;
      }
      .all {
        font-family: 'Geist';
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: #5B3DE9;
        cursor: pointer;
      }
    }
    .invitesContent {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 32px;
      overflow: auto;
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 3px;
      }
      &.invitesContentLess {
        justify-content: flex-start;
      }
    }
  }
  .toDos {
    padding: 0 80px;
    margin-bottom: 56px;
    .toDosHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      margin-bottom: 8px;
      .headTitle {
        font-family: 'Geist';
        font-size: 20px;
        font-weight: 600;
        line-height: 40px;
        color: #1C2024;
      }
    }
    .toDosContent {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 32px;
      &.toDosContentLess {
        justify-content: flex-start;
      }
    }
  }
  .bottomContainer {
    background-color: #fff;
    padding: 24px 80px 80px 80px;
    border-radius: 40px 40px 0 0 ;
    .creatorSteps {
      margin-bottom: 60px;
      .stepsHeader {
        height: 40px;
        font-family: 'Geist';
        font-size: 20px;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 12px;
        color: #1C2024;
      }
      .stepsContent {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 32px;
      }
    }
    .resources {
      .resourceTitle {
        height: 32px;
        font-family: 'Geist';
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 8px;
      }
      .resourceContent {
        display: flex;
        align-items: stretch;
        justify-content: space-start;
        gap: 32px;
      }
    }
  }
  // mobile
  &.mobileContainer {
    .title {
      padding: 16px 16px 24px;
      margin-bottom: 16px;
      font-family: 'Geist';
      font-size: 24px;
      line-height: 32px;
    }
    .notices {
      padding: 0 16px;
      margin-bottom: 16px;
    }
    .invites {
      padding: 0;
      padding-left: 16px;
      .invitesHead {
        margin-bottom: 16px;
        padding-right: 16px;
        .headTitle {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
        }
      }
      .invitesContent {
        margin-left: -16px;
        overflow: auto;
        padding: 0 16px;
        gap: 16px;
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    }
    .toDos {
      padding: 0 16px;
      overflow: auto;
      .toDosContent {
        display: block;
      }
    }
    .bottomContainer {
      padding: 28px 16px 24px 16px;
      .creatorSteps {
        margin-bottom: 40px;
        .stepsHeader {
          font-family: 'Geist';
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 24px;
        }
        .stepsContent {
          display: block;
          border: 1px solid #eee;
          padding: 24px 16px;
          border-radius: 8px;
        }
      }
      .resources {
        .resourceTitle {
          line-height: 28px;
        }
        .resourceContent {
          display: block;
        }
      }
    }
  }
}
