.imgCtn {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #00000080;
    overflow: hidden;
}

.icon {
    width: 48px;
    animation: rotate 1.5s linear infinite;
}


@keyframes rotate {
    0% {
        transform: rotate(0);
    }


    100% {
        transform: rotate(360deg);
    }
}
