.content {
  width: 100%;
  .contentDesc {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1C2024;
    margin-bottom: 16px;
  }
  .desc {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1C2024;
    margin-bottom: 16px;
  }
  .itemInput {
    margin-bottom: 16px;
    > input {
      width: 100%;
      height: 36px;
      border: 1px solid #E4E4E7;
      border-radius: 8px;
      padding-left: 12px;
      line-height: 36px;

      &:focus, &:active, &:focus-visible {
        outline: none;
      }
    }
  }
  .itemError  {
    color: #D43710;
    font-size: 12px;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
