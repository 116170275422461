.container {
  background-color: #f7f7f7;
  height: calc(100vh - 81px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 1062px;
    .exit {
      cursor: pointer;
      position: absolute;
      top: 20px;
      left: 20px;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #60646c;
        img {
          margin-right: 8px;
        }
      }
    }
    .main {
      background-color: #fff;
      width: 624px;
      height: 354px;
      padding: 56px 24px;
      padding-top: 0px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .animation {
        height: 130px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        > div {
          width: 100% !important;
          height: 100% !important;
        }
      }
      .time {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Geist Mono;
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
        text-align: center;
        color: #1c2024;
        .unit {
          font-family: Geist Mono;
          font-size: 40px;
          font-weight: 600;
          line-height: 60px;
          text-align: center;
          color: #60646c;
        }
      }
      .desc {
        font-family: Inter;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #1c2024;
      }
    }
  }
}

.mobileContainer {
  .wrapper {
    max-width: calc(100vw - 32px);
    .exit {
      display: none;
    }
    .main {
      width: 100%;
    }
  }
}

