.payment {
    padding: 24px 16px;
    font-family: Geist;
    color: #60646C;
}

.title {
    margin-bottom: 8px;
    font-size: 20px;
    font-family: Inter-Medium;
    line-height: 28px;
    color: #1C2024;
}


.desc {
    margin-bottom: 40px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #60646C;
}

.input {
    width: 358px;
}

.label {
    margin-bottom: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}

.labelIcon {
    color: #F05331;
}

.inputCtn {
    position: relative;
    margin-bottom: 32px;
}

.arrowIcon {
    position: absolute;
    right: 0;
    bottom: 8px;
    width: 10.67px;
}

.limitNum {
    position: absolute;
    right: 0;
    bottom: 8px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #60646C;
}

.mTextArea {
    margin-top: 8px !important;
    width: 100%;
    min-height: 58px;
    font-family: Inter;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px !important;
    border-bottom: 1px solid #D9D9E0 !important;
}


.joinButton {
    margin: 0 0 12px !important;
    width: 358px;
    height: 38px;
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    color: white !important;
    background-color: #5A31F0 !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.notInterestedButton {
    width: 358px;
    height: 38px;
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    color: #1C2024 !important;
    background-color: #FFFFFF !important;
    border-radius: 8px !important;
    border: 1px solid #E8E8EC !important;
    box-shadow: none !important;
    text-wrap: nowrap!important;
    padding: 0!important;
}

.bottom {
    position: fixed;
    left: calc(50% - 195px);
    bottom: 0;
    width: 390px;
    padding: 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 10px 0px #0000000D;
}

.drawerTitle {
    position: relative;
    font-size: 16px;
    font-family: Geist-Medium;
    line-height: 24px;
    text-align: center;
    color: #1C2024;
}

.closeIcon {
    position: absolute;
    top: 5px;
    right: 0;
    width: 13.5px;
    height: 13.5px;
    cursor: pointer;
}

.selectItemList {
    margin-top: 16px;
    max-height: 264px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #1C2024;
    overflow-y: scroll;
}

.submitButton {
    margin: 24px 0 8px !important;
    width: 358px;
    height: 38px;
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    color: white !important;
    background-color: #5A31F0 !important;
    border-radius: 8px !important;
}


.cancelButton {
    width: 358px;
    height: 38px;
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    color: #1C2024 !important;
    background-color: #FFFFFF !important;
    border: 1px solid #E8E8EC !important;
    text-wrap: nowrap!important;
}

.radioCtn {
    display: flex;
    align-items: center;
}

.radioItem {
    margin-right: 16px;
    color: #1C2024;
}
