.container {
  padding: 16px 24px 24px;
  // width: calc((100vw - 160px - 64px) / 3);
  background-color: #F9F9FB;
  border-radius: 16px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  > a {
    display: flex;
  }
  .content {
    margin-left: 12px;
    .title {
      display: flex;
      height: 32x;
      align-items: center;
      justify-content: space-between;
      font-family: 'Geist';
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
    }
    .desc {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #60646C;
    }
  }
}
.mobileContainer {
  background-color: #F9F9FB;
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 16px 24px 24px;
  &:last-child {
    margin-bottom: 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .title {
        margin-left: 12px;
        font-family: Geist;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
  .desc {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #60646C;
  }
}
