.container {
  background-color: #f7f7f7;
  width: 100%;
  padding: 40px 80px;
  min-height: calc(100% - 80px);
  position: relative;
  .cardList {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: 32px;
    .cardItem {
      // margin-bottom: 32px;
    }
  }
}
.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 320px;
  position: absolute;
  top: 50%;
  margin-top: -160px;
  width: 480px;
  left: 50%;
  margin-left: -240px;
  .img {
    width: 157px;
    height: 182px;
    background-image: url('../../../../public/modal-applied-flower.png');
    background-repeat: no-repeat;
    background-size: 157px;
  }
  .emptyText {
    width: 480px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    padding: 0 45px;
    color: rgba(28, 50, 72, 0.65);
    margin-top: 16px;
  }
}

.mobileContainer {
  padding: 24px 16px;
  background-color: #f7f7f7;
  min-height: calc(100% - 64px);
  .cardList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .cardItem {
      width: 100%;
      // margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}
