.container {
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #5A31F0;
	border-radius: 8px;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
}
.scriptContainer {
	width: 100%;
	.scriptDes {
		font-family: 'Inter';
		color: #60646C;
		font-size: 12px;
		line-height: 16px;
	}

	.draftLink {
		margin-top: 16px;
		font-size: 12px;
		.draftLinkText {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 6px;

			.draftLinkTitle {
				color: #60646C;
			}
			.draftLinkHelp {
				color: #60646C;
			}
		}
		.input {
			height: 40px;
			line-height: 40px;
			width: 100%;
			border: 1px solid #E4E4E7;
			border-radius: 8px;
			padding-left: 12px;
			font-size: 14px;
			
			input {
				height: 40px;
				line-height: 40px;
				box-sizing: border-box;
			}
		}
		.textArea {
			padding: 8px 12px;
			min-height: 80px;
			width: 100%;
			border: 1px solid #E4E4E7;
			border-radius: 8px;
			font-size: 14px;
		}
		.inputError {
			border-color: #d43710;
		}
		.errorDesc {
			margin-top: 6px;
			color: #d43710;
			font-size: 12px;
		}
	}
}
