.drawerTitle {
    position: relative;
    font-family: Geist-Medium;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1C2024;
}

.desc {
    margin-top: 16px;
    padding-bottom: 20px;
    height: 65vh;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #60646C;
    overflow-y: scroll;
    border-top: 1px solid #E0E1E6;
    padding-top: 16px;
}

.closeIcon {
    position: absolute;
    top: -8px;
    left: -8px;
    width: 40px;
    fill: #60646C;
    cursor: pointer;
}

.firstTextCtn{
    margin-top: 8px;
    display: flex;
}

.lastTextCtn{
    padding-bottom: 8px;
    display: flex;
}

.lastSubTextCtn{
    padding-left: 8px;
    padding-bottom: 8px;
    display: flex;
}


.textCtn {
    display: flex;
}

.subTextCtn {
    display: flex;
    padding-left: 8px;
}

.subTitle {
    margin: 16px 0 0;
    font-family: Geist-Medium;
    line-height: 20px;
    color: #1C2024;
}

.label{
    margin-right: 4px;
}

.paragraph{
    padding: 8px 0 ;
}

.li {
    position: relative;
    padding-left: 18px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #60646C;

    &::before {
        position: absolute;
        top: 9px;
        left: 3px;
        content: "";
        background-color: #60646C;
        border-radius: 50%;
        display: inline-block;
        height: 4px;
        width: 4px;
        margin-right: 8px;
    }
}
