.container {
  position: relative;
  border-top: 1px solid #E8E8EC;
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 50%;
    transform: translateX(-50%);
    top: -12px;
  }

  .title {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #8B8D98;
    margin-top: 16px;
  }

  .price {
font-size: 16px;
font-weight: 600;
line-height: 28px;
color: #1C2024;
margin-top: 4px;
  }
}
