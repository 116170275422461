.container {
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 16px;
  width: calc((100% - 64px) / 3);
  overflow: hidden;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  .header {
    width: 100%;
    height: 8px;
    background-color: #C9CCFF;
  }
  .content {
    padding: 16px 24px;
    .title {
      font-family: 'Geist';
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #1C2024;
    }
    .desc {
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #60646C;
      margin-bottom: 32px;
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .submit {
        width: 102px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        color: #fff;
        background-color: #5A31F0;
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        span {
          margin-right: 4px;
        }
      }
    }
  }
  &.mobileContainer {
    width: 100%;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
