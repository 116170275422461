.header {
  position: relative;
  .title {
    font-size: 20px;
    line-height: 32px;
    color: #1C2024;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;

  .cancel {
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #5A31F0;
    margin-right: 8px;
    color: #5A31F0;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
  }

  .submit {
    min-width: 230px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color:#5A31F0;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    &.submitDisabled {
      background-color:#9898ff;
      cursor: no-drop !important;
      color: #fff;
      &:hover {
        background-color:#9898ff;
      }
      .loading {
        color: #fafafa;
      }
    }
  }
}
.loading {
  width: 14px !important;
  height: 14px !important;
  margin-right: 8px;
  color: #fafafa;
}
